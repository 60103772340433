//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FCImage from '~/components/FCImage.vue';

export default {
  name: 'ImgVidSection',
  components: {
    FCImage,
  },
  props: {
    imgUrl: {
      type: String,
      default: '',
    },
    gifUrl: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    imgAlt: {
      type: String,
      default: '',
    },
  },
};
