//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImgVidSection from '~/components/ImgVidSection.vue';

export default {
  name: 'KickboxingImgVidSections',
  components: {
    ImgVidSection,
  },
  data() {
    return {
      gifSections: [
        {
          gifUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-kickboxing-paths-vertical-small-001.mp4',
          imgUrl: 'v4/fc-your-path-to-success-002.jpg',
          title: 'Your Path To Success',
          imgAlt: 'Woman looking at her progress after completing a workout on mobile app',
          description: 'Follow along with our Kickboxing Path—a series of workouts built to help you improve over time and reach the next level—and take the guesswork out of your training routine. Gain new skills and unlock achievements along the way.',
        },
        {
          gifUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-kickboxing-leaderboard-small-001.mp4',
          imgUrl: 'v4/fc-friendly-competition-woman-kicking-001.jpg',
          title: 'Friendly Competition For Extra Motivation',
          imgAlt: 'Woman kicking free standing punching bag',
          description: 'On the Leaderboard, see how your Output score stacks up against others in our growing community. Compete against yourself or fight your way to the top.',
          reverse: true,
        },
      ],
    };
  },
};
